exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-doelgroep-tsx": () => import("./../../../src/pages/doelgroep.tsx" /* webpackChunkName: "component---src-pages-doelgroep-tsx" */),
  "component---src-pages-faciliteiten-tsx": () => import("./../../../src/pages/faciliteiten.tsx" /* webpackChunkName: "component---src-pages-faciliteiten-tsx" */),
  "component---src-pages-historie-tsx": () => import("./../../../src/pages/historie.tsx" /* webpackChunkName: "component---src-pages-historie-tsx" */),
  "component---src-pages-impressie-tsx": () => import("./../../../src/pages/impressie.tsx" /* webpackChunkName: "component---src-pages-impressie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("./../../../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */)
}

