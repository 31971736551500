/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

// You can delete this file if you're not using it
import "./src/styles/app.css"

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false
